import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"


const showToast = (message, type = 'info',capitalize=true, duration = 3000) => {

  return new Promise((resolve, reject) => {
    if (!message) {
      resolve();
    }
    const styles = {
      info: {
        background: '#333233',
      },
      error: {
        background: '#333233',
      },
      warning: {
        background: '#333233',
      },
      success: {
        background: '#333233',
      },
    }
    // const styles = {
    //   info: {
    //     background: '#2193b0', 
    //     background: '-webkit-linear-gradient(to right, #2193b0, #6dd5ed)',
    //     background: 'linear-gradient(to right, #2193b0, #6dd5ed)' 
    //   },
    //   error: {
    //     background: '#870000',  
    //     background: '-webkit-linear-gradient(to right, #190A05, #870000)',  
    //     background: 'linear-gradient(to right, #190A05, #870000)' 

    //   },
    //   warning: {
    //     background: '#f12711',  
    //     background: '-webkit-linear-gradient(to right, #f5af19, #f12711)',  
    //     background: 'linear-gradient(to right, #f5af19, #f12711)' 

    //   },
    //   success: {
    //     background: '#11998e',  
    //     background: '-webkit-linear-gradient(to right, #38ef7d, #11998e)',  
    //     background: 'linear-gradient(to right, #38ef7d, #11998e)' 

    //   }
    // }

    // if(capitalize){
    //   message = message.toLowerCase();
    //   message = message[0].toUpperCase() + message.slice(1);
    // }

    Toastify({
      text: message,
      duration,
      gravity: "bottom", // `top` or `bottom`
      position: "right", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: styles[type],
      callback: function () {
        resolve();
      },
      /* onClick: function(){} // Callback after click */
    }).showToast();
  })


}

export default showToast;