//import $ from "jquery";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import ClientProfileComponent from './users/components/ClientProfileComponent';
import PhotographerProfileComponent from './users/components/PhotographerProfileComponent';
import { useGlobalState } from "./store";
import branch from 'branch-sdk';
const NotificationComponent = React.lazy(() => import('./notification/components/NotificationComponent'));
const ProfileErrorComponent = React.lazy(() => import('./users/components/ProfileErrorComponent'));
const PhotographerListingComponent = React.lazy(() => import('./users/components/PhotographerListingComponent'));
const PhotographerListingPaymentComponent = React.lazy(() => import('./users/components/PhotographerListingPaymentComponent'));
const TotalNotificationCountComponent = React.lazy(() => import('./notification/components/TotalNotificationCountComponent'));
const ChatCount = React.lazy(() => import('./notification/components/ChatCount'));
document.addEventListener('DOMContentLoaded', () => {
var photographerContainer = document.getElementById('photographer-profile-root');
var clientContainer = document.getElementById('client-profile-root');
let userId = null
const id = window.location.href.split('/')[window.location.href.split('/').length - 1];

if (rData.user_roles.includes('admin') && id !== "my-account") {
  userId = id
}
else {
  userId = rData.current_user_id
}

window.onpageshow = function (event) {
  if (event.persisted) {
      window.location.reload();
  }
};

// // Safari broswer caching solution
// window.addEventListener('beforeunload', function() {
//   window.onunload = function() { };
// });


// if(!sessionStorage.getItem('bookingFlowData') && localStorage.getItem('bookingFlowData')) {
//   sessionStorage.setItem("bookingFlowData", localStorage.getItem('bookingFlowData'));
//   localStorage.removeItem("bookingFlowData");
// }

if(window.location.href.includes('/logout')){
  localStorage.clear();
}

if(window.location.href.includes('/logout') || window.location.href.includes('/payment-confirmed')) sessionStorage.clear();
if((rData?.photographer_role || rData?.user_roles?.includes('admin') || window.location.href.includes('/logout'))  && sessionStorage.getItem('bookingFlowData')) {
  sessionStorage.removeItem('bookingFlowData');
}

if (photographerContainer) {
  var app;

  (function () {
    'use strict';

    app = {
      monthAndSlashRegex: /^\d\d \/ $/, // regex to match "MM / "
      monthRegex: /^\d\d$/, // regex to match "MM"

      el_cardNumber: '.ccFormatMonitor',
      el_expDate: '#inputExpDate',
      el_cvv: '.cvv',
      el_ccUnknown: 'cc_type_unknown',
      el_ccTypePrefix: 'cc_type_',
      el_monthSelect: '#monthSelect',
      el_yearSelect: '#yearSelect',

      cardTypes: {
        'American Express': {
          name: 'American Express',
          code: 'ax',
          security: 4,
          pattern: /^3[47]/,
          valid_length: [15],
          formats: {
            length: 15,
            format: 'xxxx xxxxxxx xxxx'
          }
        },
        'Visa': {
          name: 'Visa',
          code: 'vs',
          security: 3,
          pattern: /^4/,
          valid_length: [16],
          formats: {
            length: 16,
            format: 'xxxx xxxx xxxx xxxx'
          }
        },
        'Maestro': {
          name: 'Maestro',
          code: 'ma',
          security: 3,
          pattern: /^(50(18|20|38)|5612|5893|63(04|90)|67(59|6[1-3])|0604)/,
          valid_length: [16],
          formats: {
            length: 16,
            format: 'xxxx xxxx xxxx xxxx'
          }
        },
        'Mastercard': {
          name: 'Mastercard',
          code: 'mc',
          security: 3,
          pattern: /^5[1-5]/,
          valid_length: [16],
          formats: {
            length: 16,
            format: 'xxxx xxxx xxxx xxxx'
          }
        }
      }
    };

    app.addListeners = function () {
      $(app.el_expDate).on('keydown', function (e) {
        app.removeSlash(e);
      });

      $(app.el_expDate).on('keyup', function (e) {
        app.addSlash(e);
      });

      $(app.el_expDate).on('blur', function (e) {
        app.populateDate(e);
      });

      $(app.el_cvv + ', ' + app.el_expDate).on('keypress', function (e) {
        return e.charCode >= 48 && e.charCode <= 57;
      });
    };

    app.addSlash = function (e) {
      var isMonthEntered = app.monthRegex.exec(e.target.value);
      if (e.key >= 0 && e.key <= 9 && isMonthEntered) {
        e.target.value = e.target.value + " / ";
      }
    };

    app.removeSlash = function (e) {
      var isMonthAndSlashEntered = app.monthAndSlashRegex.exec(e.target.value);
      if (isMonthAndSlashEntered && e.key === 'Backspace') {
        e.target.value = e.target.value.slice(0, -3);
      }
    };

    app.populateDate = function (e) {
      var month, year;

      if (e.target.value.length == 7) {
        month = parseInt(e.target.value.slice(0, -5));
        year = "20" + e.target.value.slice(5);

        if (app.checkMonth(month)) {
          $(app.el_monthSelect).val(month);
        } else {
          $(app.el_monthSelect).val(0);
        }

        if (app.checkYear(year)) {
          $(app.el_yearSelect).val(year);
        } else {
          $(app.el_yearSelect).val(0);
        }

      }
    };

    app.checkMonth = function (month) {
      if (month <= 12) {
        var monthSelectOptions = app.getSelectOptions($(app.el_monthSelect));
        month = month.toString();
        if (monthSelectOptions.includes(month)) {
          return true;
        }
      }
    };

    app.checkYear = function (year) {
      var yearSelectOptions = app.getSelectOptions($(app.el_yearSelect));
      if (yearSelectOptions.includes(year)) {
        return true;
      }
    };

    app.getSelectOptions = function (select) {
      var options = select.find('option');
      var optionValues = [];
      for (var i = 0; i < options.length; i++) {
        optionValues[i] = options[i].value;
      }
      return optionValues;
    };

    app.setMaxLength = function ($elem, length) {
      if ($elem.length && app.isInteger(length)) {
        $elem.attr('maxlength', length);
      } else if ($elem.length) {
        $elem.attr('maxlength', '');
      }
    };

    app.isInteger = function (x) {
      return (typeof x === 'number') && (x % 1 === 0);
    };

    app.createExpDateField = function () {
      $(app.el_monthSelect + ', ' + app.el_yearSelect).hide();
      $(app.el_monthSelect).parent().prepend('<input type="text" class="ccFormatMonitor">');
    };


    app.isValidLength = function (cc_num, card_type) {
      for (var i in card_type.valid_length) {
        if (cc_num.length <= card_type.valid_length[i]) {
          return true;
        }
      }
      return false;
    };

    app.getCardType = function (cc_num) {
      for (var i in app.cardTypes) {
        var card_type = app.cardTypes[i];
        if (cc_num.match(card_type.pattern) && app.isValidLength(cc_num, card_type)) {
          return card_type;
        }
      }
    };

    app.getCardFormatString = function (cc_num, card_type) {
      for (var i in card_type.formats) {
        var format = card_type.formats[i];
        if (cc_num.length <= format.length) {
          return format;
        }
      }
    };

    app.formatCardNumber = function (cc_num, card_type) {
      var numAppendedChars = 0;
      var formattedNumber = '';
      var cardFormatIndex = '';

      if (!card_type) {
        return cc_num;
      }

      var cardFormatString = app.getCardFormatString(cc_num, card_type);
      for (var i = 0; i < cc_num.length; i++) {
        cardFormatIndex = i + numAppendedChars;
        if (!cardFormatString || cardFormatIndex >= cardFormatString.length) {
          return cc_num;
        }

        if (cardFormatString.charAt(cardFormatIndex) !== 'x') {
          numAppendedChars++;
          formattedNumber += cardFormatString.charAt(cardFormatIndex) + cc_num.charAt(i);
        } else {
          formattedNumber += cc_num.charAt(i);
        }
      }

      return formattedNumber;
    };

    app.monitorCcFormat = function ($elem) {
      var cc_num = $elem.val().replace(/\D/g, '');
      var card_type = app.getCardType(cc_num);
      $elem.val(app.formatCardNumber(cc_num, card_type));
      app.addCardClassIdentifier($elem, card_type);
    };

    app.addCardClassIdentifier = function ($elem, card_type) {
      var classIdentifier = app.el_ccUnknown;
      if (card_type) {
        classIdentifier = app.el_ccTypePrefix + card_type.code;
        app.setMaxLength($(app.el_cvv), card_type.security);
      } else {
        app.setMaxLength($(app.el_cvv));
      }

      if (!$elem.hasClass(classIdentifier)) {
        var classes = '';
        for (var i in app.cardTypes) {
          classes += app.el_ccTypePrefix + app.cardTypes[i].code + ' ';
        }
        $elem.removeClass(classes + app.el_ccUnknown);
        $elem.addClass(classIdentifier);
      }
    };


    app.init = function () {

      $(document).find(app.el_cardNumber).each(function () {
        var $elem = $(this);
        if ($elem.is('input')) {
          $elem.on('input', function () {
            app.monitorCcFormat($elem);
          });
        }
      });

      app.addListeners();

    }();

  })();
  ReactDOM.render(
    <PhotographerProfileComponent useGlobalState={useGlobalState} userId={userId} isAdmin={rData.user_roles.includes('admin')} />,
    photographerContainer
  )
} else if (clientContainer) {
  ReactDOM.render(
    <ClientProfileComponent useGlobalState={useGlobalState} userId={userId} isAdmin={rData.user_roles.includes('admin')} />,
    clientContainer
  )
}

if (document.getElementById('notifications_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <NotificationComponent />
    </Suspense>
    ,
    document.getElementById('notifications_root'),
  );
}
if (!rData?.user_roles?.includes('admin') && document.getElementById('profile-error-root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <ProfileErrorComponent useGlobalState={useGlobalState} />
    </Suspense>
    ,
    document.getElementById('profile-error-root'),
  );
}
if (document.getElementById('photographer-listing-root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <PhotographerListingComponent />
    </Suspense>
    ,
    document.getElementById('photographer-listing-root'),
  );
}

if (document.getElementById('photographer-listing-payment-root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <PhotographerListingPaymentComponent />
    </Suspense>
    ,
    document.getElementById('photographer-listing-payment-root'),
  );
}
if (document.getElementById('total_notification_count_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <TotalNotificationCountComponent badge="notification" />
    </Suspense>,
    document.getElementById('total_notification_count_root'),
  );
}
if (rData?.current_user_sendbird_access_token && rData?.current_user_sendbird_uid && document.getElementById('chat_unread_notification_count_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <ChatCount className="position-absolute translate-up" />
    </Suspense>,
    document.getElementById('chat_unread_notification_count_root'),
  );
}

if (rData?.current_user_sendbird_access_token && rData?.current_user_sendbird_uid && document.getElementById('mobile_chat_unread_notification_count_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <ChatCount className="translate-middle mobile_unread_chat" />
    </Suspense>,
    document.getElementById('mobile_chat_unread_notification_count_root'),
  );
}

if (document.getElementById('total_notification_count_mobile_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <TotalNotificationCountComponent badge="notification" />
    </Suspense>,
    document.getElementById('total_notification_count_mobile_root'),
  );
}

if (document.getElementById('current_user_profile_pic_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <ProfilePicComponent useGlobalState={useGlobalState} />
    </Suspense>,
    document.getElementById('current_user_profile_pic_root'),
  );
}
if (document.getElementById('current_user_profile_pic_2_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <ProfilePicComponent useGlobalState={useGlobalState} />
    </Suspense>,
    document.getElementById('current_user_profile_pic_2_root'),
  );
}

if (document.getElementById('name_root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <NameComponent useGlobalState={useGlobalState} />
    </Suspense>,
    document.getElementById('name_root'),
  );
}
if (document.getElementById('name__2root')) {
  ReactDOM.render(
    <Suspense fallback={<div></div>}>
      <NameComponent useGlobalState={useGlobalState} />
    </Suspense>,
    document.getElementById('name__2root'),
  );
}
});
const ProfilePicComponent = ({useGlobalState}) =>{
  const [profilePicture,setProfilePicture]=useGlobalState('profile_pic')

  return (
<>
<img 
 src={rData?.user_roles?.includes('admin') ? (rData?.current_user_profile_pic || ASSETS?.user_default_avatar) : (profilePicture || rData?.current_user_profile_pic || ASSETS?.user_default_avatar)}
 onError={(e) => e.target.src = ASSETS.user_default_avatar}
 />
</>
  );
}

const NameComponent = ({useGlobalState}) =>{
  const [name,setName]=useGlobalState('full_name')
  return (
      <>
      {name}
      </>
  );
}
// $(document).ready(function () {
//   try {
//     $('#creds').addClass('active');
//     let creds = localStorage.getItem('test-credentials');
//     if (!creds || creds != 'mamsyscheezoo@139') {
//       throw new Error('credentials not present');
//     }
//     $('#creds').removeClass('active');

//   } catch (error) {
//     let answer;
//     while (answer != 'mamsyscheezoo@139') {
//       answer = window.prompt('Enter password for cheezoo');
//     }
//     if (answer == 'mamsyscheezoo@139') {
//       $('#creds').removeClass('active');
//       localStorage.setItem('test-credentials', answer);
//     }
//   }

// })


function logEvent(type) {
  branch.init('key_live_bylDugIHptBuY8IJfPbGnllhxwmZwNST', function (err, data) {
    if (err) {
      console.error('Branch initialization error:', err);
    } else {
      // console.log('Branch SDK initialized successfully:', data);

      branch.logEvent(
        type + "View",
        {
          user_id: rData?.current_user_id,
        },
        function (err) {
          // if (err) {
          //   console.error('Error logging event:', err);
          // } else {
          //   console.log('Event logged successfully:', type);
          // }
        });
    }
  })

}




const value = location.href;
if (value.includes('/user/my-account')) {
  logEvent('UserProfile');
} else if (value.includes('/search-events')) {
  logEvent('SearchClient');
} else if (value.includes('/search')) {
  logEvent('SearchPhotographer');
} else if (value.includes('/events/')) {
  logEvent('PhotoShootDetail');
} else if (value.includes('/events?filter=')) {
  logEvent('PhotoShootListing');
} else if (value.includes('/get-started')) {
  logEvent('GetStarted');
} else if (value.includes('/choose-role')) {
  logEvent('ChooseRole');
} else if (value.includes('/register-client')) {
  logEvent('RegisterClient');
} else if (value.includes('/register-photographer')) {
  logEvent('RegisterPhotographer');
}

